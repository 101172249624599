<template>
  <app-collapse-item :is-visible="true" title="Web Solution Briefing">
    <b-row>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="DID number"
          rules=""
        >
          <HeroInputText
            id="did-number"
            :value="getDidNumber"
            label="DID Number"
            :add-more="true"
            :readonly="true"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
            @addMore="$bvModal.show('did-number')"
          />
        </validation-provider>
        <AddDidNumber :web-sol-briefing="webSolBriefing" />
      </b-col>
      <b-col v-if="isEdit" cols="12" md="4">
        <HeroInputText
          id="domain-expire-date"
          :value="getDate(webSolBriefing.domain_expire_date)"
          label="Domain Expiring Date"
          :readonly="true"
        />
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="email lead list"
          rules=""
        >
          <HeroInputText
            id="email-lead-list"
            :value="getEmailLeadList"
            label="Email Lead List"
            :add-more="true"
            :readonly="true"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
            @addMore="$bvModal.show('email-lead-list')"
          />
        </validation-provider>
        <AddEmailLeads :web-sol-briefing="webSolBriefing" />
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="email notification language"
          rules=""
        >
          <HeroVueSelect
            id="email-notification-language"
            v-model="webSolBriefing.email_notification_language"
            label="Email Notification Language"
            :clearable="false"
            :options="languageOptions"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="sms lead number"
          rules=""
        >
          <HeroInputText
            id="sms-lead-number"
            :value="getSmsLeadNumber"
            label="SMS Lead Number"
            :add-more="true"
            :readonly="true"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
            @addMore="$bvModal.show('sms-lead-number')"
          />
        </validation-provider>
        <AddSmsLead :web-sol-briefing="webSolBriefing" />
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="sms notification language"
          rules=""
        >
          <HeroVueSelect
            id="sms-notification-language"
            v-model="webSolBriefing.sms_notification_language"
            label="SMS Notification Language"
            :clearable="false"
            :options="languageOptions"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="line notification number"
          rules=""
        >
          <HeroInputText
            id="line-notification-number"
            :value="getEmail"
            label="Line Notification Number"
            :add-more="true"
            :readonly="true"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
            @addMore="$bvModal.show('line-notification-number')"
          />
        </validation-provider>
        <AddLineNotificationNumber :web-sol-briefing="webSolBriefing" />
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="line notification language"
          rules=""
        >
          <HeroVueSelect
            id="line-language-name"
            v-model="webSolBriefing.line_notifications_language_id"
            label="Line Notification Language"
            :clearable="false"
            :options="languageOptions"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
      <b-col cols="12" md="4">
        <HeroVueSelect
          id="landing-page-type-name"
          v-model="webSolBriefing.landing_page_type_id"
          label="Landing Page Type"
          :options="landingPageTypeOptions"
        />
      </b-col>
      <b-col cols="12" md="4">
        <HeroVueSelect
          id="landing-page-language-name"
          v-model="webSolBriefing.landing_page_language_id"
          label="Landing Page Language"
          :clearable="false"
          :options="languageOptions"
        />
      </b-col>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="Landing Target URL"
          rules="url"
        >
          <HeroInputText
            id="landing-target-url"
            v-model="webSolBriefing.landing_target_url"
            label="Landing Target URL"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import Ripple from 'vue-ripple-directive'
import {
  BRow,
  BCol,
  VBModal,
} from 'bootstrap-vue'
import AddEmailLeads from '@/views/heroai/campaigns/components/create/AddEmailLeads.vue'
import AddSmsLead from '@/views/heroai/campaigns/components/create/AddSmsLead.vue'
import AddDidNumber from '@/views/heroai/campaigns/components/create/AddDidNumber.vue'
import AddLineNotificationNumber from '@/views/heroai/campaigns/components/create/AddLineNotificationNumber.vue'
import WebSolutionBriefing from '@/models/Campaigns/WebSolutionBriefing'
import DataFormatService from '@/services/DataFormatService'
import Store from '@/store/index'

export default {
  components: {
    AppCollapseItem,
    BRow,
    BCol,
    HeroInputText,
    HeroVueSelect,
    AddEmailLeads,
    AddSmsLead,
    AddDidNumber,
    AddLineNotificationNumber,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    webSolBriefing: {
      type: Object,
      require: true,
      default: () => new WebSolutionBriefing({}),
    },
    isEdit: {
      type: Boolean,
    },
    landingPageTypeOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    languageOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
  },
  data() {
    return {
      email_lead_lists: '',
      sms_lead_numbers: '',
      did_numbers: '',
      emails: '',
      token: '',
    }
  },
  computed: {
    getEmailLeadList() {
      if (this.webSolBriefing.email_lead_list && this.webSolBriefing.email_lead_list.length > 0) {
        return this.webSolBriefing.email_lead_list.join(', ')
      }
      return ''
    },
    getSmsLeadNumber() {
      if (this.webSolBriefing.sms_lead_number && this.webSolBriefing.sms_lead_number.length > 0) {
        return this.webSolBriefing.sms_lead_number.join(', ')
      }
      return ''
    },
    getDidNumber() {
      if (this.webSolBriefing.did_phone && this.webSolBriefing.did_phone.length > 0) {
        return this.webSolBriefing.did_phone.join(', ')
      }
      return ''
    },
    getEmail() {
      if (this.webSolBriefing.line_notification_number && this.webSolBriefing.line_notification_number.length > 0) {
        return this.webSolBriefing.line_notification_number.map(x => x.email).join(', ')
      }
      return ''
    },
  },
  watch: {
    webSolBriefing: {
      handler(newValue) {
        if (newValue.email_lead_list && newValue.email_lead_list.length > 0) {
          this.email_lead_lists = newValue.email_lead_list.join(', ')
        }
        if (newValue.sms_lead_numbers && newValue.sms_lead_numbers.length > 0) {
          this.sms_lead_numbers = newValue.sms_lead_number.join(', ')
        }
        if (newValue.did_phone && newValue.did_phone.length > 0) {
          this.did_numbers = newValue.did_phone.join(', ')
        }
        const emailsArray = []
        this.webSolBriefing.line_notification_number.forEach(x => {
          if (x.email) {
            emailsArray.push(x.email)
          }
        })
        this.emails = emailsArray.join(', ')
        Store.commit('heroAiCampaign/setWebSolutionBriefing', newValue)
      },
      deep: true,
    },
  },
  methods: {
    getDate(value) {
      const date = DataFormatService.formatUsaDate(value)
      if (date) {
        return date
      }
      return ''
    },
  },
}
</script>
