<template>
  <validation-observer ref="dataRules" v-slot="{ invalid }" tag="form">
    <b-modal
      :id="id"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @shown="onShown"
    >
      <b-card-code :title="title">
        <div class="card-body-data">
          <b-form
            ref="form"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent="repeatAgain"
          >
            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col cols="10">
                <validation-provider
                  #default="{ errors }"
                  :vid="`${label}-${index}`"
                  :name="label"
                  :rules="rules"
                >
                  <HeroInputText
                    :id="`data-${index}`"
                    v-model="dataList[index]"
                    :label="label"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                    @focusout="onFocusout(errors, index)"
                  />
                </validation-provider>
              </b-col>
              <b-col
                cols="2"
                class="d-flex align-items-center"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="danger"
                  class="btn"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeatAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add {{ label }}</span>
        </b-button>
      </b-card-code>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            :disabled="invalid"
            @click="validationFormList(true)"
          >
            Save
          </b-button>
          <b-button
            variant="danger"
            size="md"
            class="float-right mr-2"
            @click="validationFormList(false)"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    HeroInputText,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    id: {
      type: String,
      require: true,
      default: '',
    },
    title: {
      type: String,
      default: 'Enter data',
    },
    label: {
      type: String,
      default: 'Data',
    },
    rules: {
      type: String,
      default: 'required|email',
    },
    initialDataList: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      isInitialized: false,
      items: [{
        id: 1,
        prevHeight: 0,
        error: false,
      }],
      nextTodoId: 2,
      dataList: [],
    }
  },
  computed: {
    itemErrorCount() {
      return this.items.filter(item => item.error).length
    },
  },
  watch: {
    itemErrorCount(val, preVal) {
      let diffVal = 0
      if (val >= preVal) {
        diffVal = val - preVal
        this.trAddHeight(18 * diffVal)
        return
      }

      diffVal = preVal - val
      this.trTrimHeight(18 * diffVal)
    },
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async initializeData() {
      this.dataList = []
      if (this.initialDataList.length) {
        // eslint-disable-next-line no-restricted-syntax
        for await (const [index, initialData] of this.initialDataList.entries()) {
          this.dataList.push(initialData)
          if (index > 0) {
            await this.repeatAgain()
          }
        }
      } else {
        this.dataList = ['']
      }
    },

    async onShown() {
      if (!this.isInitialized) {
        this.initTrHeight()
        await this.initializeData()
        this.isInitialized = true
      }
    },

    async repeatAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
        error: false,
      })

      if (this.isInitialized) {
        this.dataList.push('')
      }

      await this.$nextTick()

      if (this.$refs.row) {
        this.trAddHeight(this.items[0]?.error ? this.$refs.row[0].offsetHeight - 18 : this.$refs.row[0].offsetHeight)
      }
    },

    async removeItem(index) {
      if (this.$refs.row) {
        if (this.items[index].error) {
          this.trTrimHeight(this.$refs.row[index].offsetHeight - 18)
        } else {
          this.trTrimHeight(this.$refs.row[index].offsetHeight)
        }
      }

      this.items.splice(index, 1)
      this.dataList.splice(index, 1)

      await this.$nextTick()

      if (!this.items.length) {
        this.initTrHeight()
      }
    },

    async initTrHeight() {
      this.trSetHeight(null)

      await this.$nextTick()

      if (this.$refs.form) {
        this.trSetHeight(this.$refs.form.scrollHeight)
      }
    },

    validationFormList(isSave) {
      return new Promise((resolve, reject) => {
        if (isSave) {
          this.$refs.dataRules.validate().then(success => {
            if (success) {
              if (this.dataList) {
                const convertDataList = this.dataList.map(item => item.toLowerCase())
                const uniqueDataList = [...new Set(convertDataList)]
                if (convertDataList.length !== uniqueDataList.length) {
                  this.$swal(`Duplicate enteries in ${this.label}`)
                  reject()
                } else {
                  this.$emit('success', this.dataList)
                  this.$bvModal.hide(this.id)
                  resolve(true)
                }
              } else {
                this.$bvModal.hide(this.id)
                resolve(true)
              }
            } else {
              reject()
            }
          })
        } else {
          this.$bvModal.hide(this.id)
          resolve(true)
        }
      })
    },
    onFocusout(errors, index) {
      this.items[index].error = !!errors.length
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.btn {
  margin-top: 8px;
}
</style>
