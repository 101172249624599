<template>
  <b-row>
    <!-- AM Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="AM"
      >
        <HeroVueSelect
          id="am"
          v-model="staffAssignment.account_manager.id"
          label="AM"
          :options="amOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('am')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="AM point"
      >
        <HeroVueSelect
          id="am-point"
          v-model="staffAssignment.account_manager.user_level_points"
          label="AM Point"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null"
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- AM End -->

    <!-- Designer1 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="designer 1"
      >
        <HeroVueSelect
          id="designer 1"
          v-model="staffAssignment.graphic_designer_1.id"
          label="Designer 1"
          :options="designerOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('designer 1')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="GD 1 point"
      >
        <HeroVueSelect
          id="gd-1-point"
          v-model="staffAssignment.graphic_designer_1.user_level_points"
          label="GD 1 Point"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Designer1 End -->

    <!-- System Support Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="system support"
      >
        <HeroVueSelect
          id="system-support"
          v-model="staffAssignment.system_support.id"
          label="System Support"
          :options="systemSupportOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('system-support')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="SS point"
      >
        <HeroVueSelect
          id="ss-point"
          v-model="staffAssignment.system_support.user_level_points"
          label="SS Point"
          :clearable="false"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
        />
      </validation-provider>
    </b-col>
    <!-- System Support End -->

    <!-- Designer2 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="designer 2"
      >
        <HeroVueSelect
          id="designer 2"
          v-model="staffAssignment.graphic_designer_2.id"
          label="Designer 2"
          :options="designerOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('designer 2')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="GD 2 point"
      >
        <HeroVueSelect
          id="gd-2-point"
          v-model="staffAssignment.graphic_designer_2.user_level_points"
          label="GD 2 Point"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Designer2 End -->

    <!-- Data Analyst Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="data analyst"
      >
        <HeroVueSelect
          id="data-analyst"
          v-model="staffAssignment.data_analyst.id"
          label="Data Analyst"
          :options="dataAnalystOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('data-analyst')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="DA point"
      >
        <HeroVueSelect
          id="da-point"
          v-model="staffAssignment.data_analyst.user_level_points"
          label="DA Point"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Digital Analyst End -->

    <!-- Designer3 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="designer 3"
      >
        <HeroVueSelect
          id="designer 3"
          v-model="staffAssignment.graphic_designer_3.id"
          label="Designer 3"
          :options="designerOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('designer 3')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="GD 3 point"
      >
        <HeroVueSelect
          id="gd-3-point"
          v-model="staffAssignment.graphic_designer_3.user_level_points"
          label="GD 3 Point"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Designer3 End -->

    <!-- Influencer Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="influencer"
      >
        <HeroVueSelect
          id="influencer"
          v-model="staffAssignment.influencer_planner.id"
          label="Influencer"
          :options="influencerOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('influencer')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="IF point"
      >
        <HeroVueSelect
          id="if-point"
          v-model="staffAssignment.influencer_planner.user_level_points"
          label="IF Point"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Infulencer End -->

    <!-- Copy Writer 1 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="copy writer 1"
      >
        <HeroVueSelect
          id="copy-writer-1"
          v-model="staffAssignment.copy_writer_1.id"
          label="Copy Writer 1"
          :options="copyWriterOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('copy-writer-1')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="copy writer 1 point"
      >
        <HeroVueSelect
          id="copy-writer-1-point"
          v-model="staffAssignment.copy_writer_1.user_level_points"
          label="Copy Writer 1 Point"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Copy Writer 1 End -->

    <!-- Digital Associate Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="digital associate"
      >
        <HeroVueSelect
          id="digital-associate"
          v-model="staffAssignment.digital_associate.id"
          label="Digital Associate"
          :options="digitalAssociateOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('digital-associate')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="DMA point"
      >
        <HeroVueSelect
          id="dma-point"
          v-model="staffAssignment.digital_associate.user_level_points"
          label="DMA Point"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Digital Associate End -->

    <!-- Copy Writer 2 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="copy writer 2"
      >
        <HeroVueSelect
          id="copy-writer-2"
          v-model="staffAssignment.copy_writer_2.id"
          label="Copy Writer 2"
          :options="copyWriterOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('copy-writer-2')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="copy writer 2 point"
      >
        <HeroVueSelect
          id="copy-writer-2-point"
          v-model="staffAssignment.copy_writer_2.user_level_points"
          label="Copy Writer 2 Point"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Copy Writer 2 End -->

    <!-- Sale Start -->
    <b-col cols="12" md="6">
      <HeroVueSelect
        id="sale"
        v-model="staffAssignment.sales"
        label="Sale"
        :options="saleOptions"
      />
    </b-col>
    <!-- Sale End -->

    <!-- Copy Writer 3 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="copy writer 3"
      >
        <HeroVueSelect
          id="copy-writer-3"
          v-model="staffAssignment.copy_writer_3.id"
          label="Copy Writer 3"
          :options="copyWriterOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('copy-writer-3')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="2">
      <validation-provider
        #default="{ errors }"
        name="copy writer 3 point"
      >
        <HeroVueSelect
          id="copy-writer-3-point"
          v-model="staffAssignment.copy_writer_3.user_level_points"
          label="Copy Writer 3 Point"
          :options="pointOptions"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Copy Writer 3 End -->

    <!-- Viewer Start -->
    <b-col cols="12" md="12">
      <HeroVueSelectMultiple
        id="viewers"
        v-model="staffAssignment.viewers"
        label="Viewers"
        :options="viewersOptions"
      />
    </b-col>
    <!-- Viewer End -->

    <!-- SEO Members Start -->
    <b-col cols="12">
      <HeroVueSelectMultiple
        id="seo-members"
        v-model="staffAssignment.seo_members"
        label="SEO Members"
        :options="viewersOptions"
      />
    </b-col>
    <!-- SEO Members End -->

    <!-- Optimizer_1 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="optimizer 1"
      >
        <HeroVueSelect
          id="optimizer_1"
          v-model="staffAssignment.optimizer_1.id"
          label="Optimizer 1"
          :options="optimizerOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('optimizer_1')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="OP1 Ad Platform"
      >
        <HeroVueSelect
          id="op1-ad-platform"
          v-model="staffAssignment.optimizer_1.ad_platform"
          label="OP1 Ad Platform"
          :options="adPlatformOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="true"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="OP1 point"
      >
        <HeroVueSelect
          id="op1-point"
          v-model="staffAssignment.optimizer_1.user_level_points"
          label="OP1 Point"
          :options="pointOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Optimizer_1 End -->

    <!-- Optimizer_2 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="optimizer 2"
      >
        <HeroVueSelect
          id="optimizer_2"
          v-model="staffAssignment.optimizer_2.id"
          label="Optimizer 2"
          :options="optimizerOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('optimizer_2')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="OP2 Ad Platform"
      >
        <HeroVueSelect
          id="op2-ad-platform"
          v-model="staffAssignment.optimizer_2.ad_platform"
          label="OP2 Ad Platform"
          :options="adPlatformOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="true"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="OP2 point"
      >
        <HeroVueSelect
          id="op2-point"
          v-model="staffAssignment.optimizer_2.user_level_points"
          label="OP2 Point"
          :options="pointOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Optimizer_2 End -->

    <!-- Optimizer_3 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="optimizer 3"
      >
        <HeroVueSelect
          id="optimizer_3"
          v-model="staffAssignment.optimizer_3.id"
          label="Optimizer 3"
          :options="optimizerOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('optimizer_3')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="OP3 Ad Platform"
      >
        <HeroVueSelect
          id="op3-ad-platform"
          v-model="staffAssignment.optimizer_3.ad_platform"
          label="OP3 Ad Platform"
          :options="adPlatformOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="true"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="OP3 point"
      >
        <HeroVueSelect
          id="op3-point"
          v-model="staffAssignment.optimizer_3.user_level_points"
          label="OP3 Point"
          :options="pointOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Optimizer_3 End -->

    <!-- Optimizer_4 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="optimizer 4"
      >
        <HeroVueSelect
          id="optimizer_4"
          v-model="staffAssignment.optimizer_4.id"
          label="Optimizer 4"
          :options="optimizerOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('optimizer_4')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="OP4 Ad Platform"
      >
        <HeroVueSelect
          id="op4-ad-platform"
          v-model="staffAssignment.optimizer_4.ad_platform"
          label="OP4 Ad Platform"
          :options="adPlatformOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="true"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="OP4 point"
      >
        <HeroVueSelect
          id="op4-point"
          v-model="staffAssignment.optimizer_4.user_level_points"
          label="OP4 Point"
          :options="pointOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Optimizer_4 End -->

    <!-- Optimizer_5 Start -->
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="optimizer 5"
      >
        <HeroVueSelect
          id="optimizer_5"
          v-model="staffAssignment.optimizer_5.id"
          label="Optimizer 5"
          :options="optimizerOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          @input="setPointsValue('optimizer_5')"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="OP5 Ad Platform"
      >
        <HeroVueSelect
          id="op5-ad-platform"
          v-model="staffAssignment.optimizer_5.ad_platform"
          label="OP5 Ad Platform"
          :options="adPlatformOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="true"
        />
      </validation-provider>
    </b-col>
    <b-col cols="12" md="4">
      <validation-provider
        #default="{ errors }"
        name="OP5 point"
      >
        <HeroVueSelect
          id="op5-point"
          v-model="staffAssignment.optimizer_5.user_level_points"
          label="OP5 Point"
          :options="pointOptions"
          :disabled="!$can('Edit', 'Campaigns > Staff Assignment > Optimizer')"
          :state="errors.length > 0 ? false : null "
          :invalid-feedback="errors[0]"
          :clearable="false"
        />
      </validation-provider>
    </b-col>
    <!-- Optimizer_5 End -->

  </b-row>
</template>

<script>
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import HeroVueSelectMultiple from '@/views/components/form/selects/HeroVueSelectMultiple.vue'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import StaffAssign from '@/models/Campaigns/StaffAssignment'
import Store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,
    HeroVueSelect,
    HeroVueSelectMultiple,
  },
  props: {
    staffAssignment: {
      type: Object,
      require: true,
      default: () => new StaffAssign({}),
    },
    amOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    optimizerOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    copyWriterOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    designerOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    systemSupportOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    dataAnalystOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    influencerOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    digitalAssociateOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    saleOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    viewersOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    pointOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    adPlatformOptions: {
      type: Array,
      require: true,
      default: () => new Array([]),
    },
    zeroPointId: {
      type: String,
      required: true,
      default: '',
    },
  },
  watch: {
    staffAssignment: {
      handler(newValue) {
        Store.commit('heroAiCampaign/setStaffAssignment', newValue)
      },
      deep: true,
    },
  },
  mounted() {
    Store.commit('heroAiCampaign/setStaffAssignment', this.staffAssignment)
  },
  methods: {
    isRequiredData(type) {
      let ruleValue = ''
      switch (type) {
        case 'am':
          if (this.staffAssignment.account_manager.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        case 'optimizer_1':
          if (this.staffAssignment.optimizer_1.user_level_points !== this.zeroPointId || this.staffAssignment.optimizer_1.ad_platform) {
            ruleValue = 'required'
          }
          break
        case 'optimizer_2':
          if (this.staffAssignment.optimizer_2.user_level_points !== this.zeroPointId || this.staffAssignment.optimizer_2.ad_platform) {
            ruleValue = 'required'
          }
          break
        case 'optimizer_3':
          if (this.staffAssignment.optimizer_3.user_level_points !== this.zeroPointId || this.staffAssignment.optimizer_3.ad_platform) {
            ruleValue = 'required'
          }
          break
        case 'optimizer_4':
          if (this.staffAssignment.optimizer_4.user_level_points !== this.zeroPointId || this.staffAssignment.optimizer_4.ad_platform) {
            ruleValue = 'required'
          }
          break
        case 'optimizer_5':
          if (this.staffAssignment.optimizer_5.user_level_points !== this.zeroPointId || this.staffAssignment.optimizer_5.ad_platform) {
            ruleValue = 'required'
          }
          break
        case 'copy-writer-1':
          if (this.staffAssignment.copy_writer_1.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        case 'copy-writer-2':
          if (this.staffAssignment.copy_writer_2.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        case 'copy-writer-3':
          if (this.staffAssignment.copy_writer_3.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        case 'designer 1':
          if (this.staffAssignment.graphic_designer_1.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        case 'designer 2':
          if (this.staffAssignment.graphic_designer_2.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        case 'designer 3':
          if (this.staffAssignment.graphic_designer_3.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        case 'system-support':
          if (this.staffAssignment.system_support.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        case 'data-analyst':
          if (this.staffAssignment.data_analyst.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        case 'influencer':
          if (this.staffAssignment.influencer_planner.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        case 'digital-associate':
          if (this.staffAssignment.digital_associate.user_level_points !== this.zeroPointId) {
            ruleValue = 'required'
          }
          break
        default:
          break
      }
      return ruleValue
    },
    setPointsValue(dropdownType) {
      // eslint-disable-next-line no-underscore-dangle
      if (dropdownType === 'am' && this.staffAssignment.account_manager.id === '') {
        this.staffAssignment.account_manager.user_level_points = this.zeroPointId
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'optimizer_1' && this.staffAssignment.optimizer_1.id === '') {
        this.staffAssignment.optimizer_1.user_level_points = this.zeroPointId
        this.staffAssignment.optimizer_1.ad_platform = ''
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'optimizer_2' && this.staffAssignment.optimizer_2.id === '') {
        this.staffAssignment.optimizer_2.user_level_points = this.zeroPointId
        this.staffAssignment.optimizer_2.ad_platform = ''
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'optimizer_3' && this.staffAssignment.optimizer_3.id === '') {
        this.staffAssignment.optimizer_3.user_level_points = this.zeroPointId
        this.staffAssignment.optimizer_3.ad_platform = ''
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'optimizer_4' && this.staffAssignment.optimizer_4.id === '') {
        this.staffAssignment.optimizer_4.user_level_points = this.zeroPointId
        this.staffAssignment.optimizer_4.ad_platform = ''
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'optimizer_5' && this.staffAssignment.optimizer_5.id === '') {
        this.staffAssignment.optimizer_5.user_level_points = this.zeroPointId
        this.staffAssignment.optimizer_5.ad_platform = ''
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'copy-writer-1' && this.staffAssignment.copy_writer_1.id === '') {
        this.staffAssignment.copy_writer_1.user_level_points = this.zeroPointId
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'copy-writer-2' && this.staffAssignment.copy_writer_2.id === '') {
        this.staffAssignment.copy_writer_2.user_level_points = this.zeroPointId
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'copy-writer-3' && this.staffAssignment.copy_writer_3.id === '') {
        this.staffAssignment.copy_writer_3.user_level_points = this.zeroPointId
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'designer 1' && this.staffAssignment.graphic_designer_1.id === '') {
        this.staffAssignment.graphic_designer_1.user_level_points = this.zeroPointId
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'designer 2' && this.staffAssignment.graphic_designer_2.id === '') {
        this.staffAssignment.graphic_designer_2.user_level_points = this.zeroPointId
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'designer 3' && this.staffAssignment.graphic_designer_3.id === '') {
        this.staffAssignment.graphic_designer_3.user_level_points = this.zeroPointId
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'system-support' && this.staffAssignment.system_support.id === '') {
        this.staffAssignment.system_support.user_level_points = this.zeroPointId
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'data-analyst' && this.staffAssignment.data_analyst.id === '') {
        this.staffAssignment.data_analyst.user_level_points = this.zeroPointId
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'influencer' && this.staffAssignment.influencer_planner.id === '') {
        this.staffAssignment.influencer_planner.user_level_points = this.zeroPointId
      // eslint-disable-next-line no-underscore-dangle
      } else if (dropdownType === 'digital-associate' && this.staffAssignment.digital_associate.id === '') {
        this.staffAssignment.digital_associate.user_level_points = this.zeroPointId
      }
    },
  },
}
</script>
