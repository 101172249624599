<template>
  <app-collapse-item :is-visible="true" title="Creative Briefing">
    <b-row>
      <b-col cols="12" md="4">
        <validation-provider
          #default="{ errors }"
          name="creative briefing link"
          rules="url"
        >
          <HeroInputText
            id="creative-briefing-link"
            v-model="creativeBriefingInfo.creative_briefing_link"
            label="Creative Briefing Link"
            :state="errors.length > 0 ? false : null "
            :invalid-feedback="errors[0]"
          />
        </validation-provider>
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroVueSelect
          id="artwork-per-month"
          v-model="creativeBriefingInfo.artwork_per_month"
          label="Do you commit amount of artwork per month"
          :options="artworkOptions"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroVueSelect
          id="product"
          v-model="creativeBriefingInfo.product"
          label="Product, service, branch information document"
          :options="productOptions"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroVueSelect
          id="any-faq"
          v-model="creativeBriefingInfo.any_faq"
          label="Any FAQ questions to add to the flow?"
          :options="faqOptions"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroVueSelect
          id="any-promotion"
          v-model="creativeBriefingInfo.any_promotion"
          label="Any promotion offer to show the user?"
          :options="promotionOptions"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroInputNumber
          id="pieces"
          v-model="creativeBriefingInfo.pieces"
          label="How many pieces?"
        />
      </b-col>
      <b-col v-show="false" cols="12" md="4">
        <HeroTextarea
          id="lead-data"
          v-model="creativeBriefingInfo.lead_item"
          label="Lead data requirements(email, phone)?"
        />
      </b-col>
    </b-row>
  </app-collapse-item>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import HeroInputNumber from '@/views/components/form/inputs/HeroInputNumber.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTextarea from '@/views/components/form/inputs/HeroTextarea.vue'
import HeroVueSelect from '@/views/components/form/selects/HeroVueSelect.vue'
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import CreativeBriefingInfo from '@/models/Campaigns/CreativeBriefing'
import Store from '@/store/index'

export default {
  components: {
    AppCollapseItem,
    BRow,
    BCol,
    HeroInputNumber,
    HeroTextarea,
    HeroVueSelect,
    HeroInputText,
  },
  props: {
    creativeBriefingInfo: {
      type: Object,
      require: true,
      default: () => new CreativeBriefingInfo({}),
    },
  },
  data() {
    return {
      artworkOptions: [
        { text: 'Yes, Commit', value: 'Yes, Commit' },
        { text: 'No, Not Commit', value: 'No, Not Commit' },
      ],
      productOptions: [
        { text: 'Yes, added in Google Drive.', value: 'Yes, added in Google Drive.' },
        { text: 'No, not required.', value: 'No, not required.' },
      ],
      faqOptions: [
        { text: 'Yes, added in Google Drive.', value: 'Yes, added in Google Drive.' },
        { text: 'No, not required.', value: 'No, not required.' },
      ],
      promotionOptions: [
        { text: 'Yes, added in Google Drive.', value: 'Yes, added in Google Drive.' },
        { text: 'No, not required.', value: 'No, not required.' },
      ],
    }
  },
  watch: {
    creativeBriefingInfo: {
      handler(newValue) {
        Store.commit('heroAiCampaign/setCreativeBriefingInfo', newValue)
      },
      deep: true,
    },
  },
}
</script>
