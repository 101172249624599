var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"campaign name","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"campaign-name","label":"Campaign Name","required":true,"readonly":_vm.isCampaignNameDisable,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.name),callback:function ($$v) {_vm.$set(_vm.campaign, "name", $$v)},expression:"campaign.name"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"campaign status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"status","label":"Campaign Status","required":true,"clearable":false,"options":_vm.campaignStatusOptions,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.campaign_status_id),callback:function ($$v) {_vm.$set(_vm.campaign, "campaign_status_id", $$v)},expression:"campaign.campaign_status_id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"business objectives","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroVueSelect',{attrs:{"id":"status","label":"Business Objectives","required":true,"clearable":false,"options":_vm.businessObjectiveOption,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.business_objectives),callback:function ($$v) {_vm.$set(_vm.campaign, "business_objectives", $$v)},expression:"campaign.business_objectives"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"brief_date","tag":"div","rules":"beforestartdate:@campaign start date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputDate',{attrs:{"id":"brief-date","name":"brief_date","label":"Brief Date","add-calendar":true,"readonly":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.brief_date),callback:function ($$v) {_vm.$set(_vm.campaign, "brief_date", $$v)},expression:"campaign.brief_date"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"campaign start date","tag":"div","rules":"required|afterstartdate:@brief_date|before:@campaign end date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputDate',{attrs:{"id":"start-date","name":"campaign start date","label":"Campaign Start Date","add-calendar":true,"required":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.actual_campaign_start_date),callback:function ($$v) {_vm.$set(_vm.campaign, "actual_campaign_start_date", $$v)},expression:"campaign.actual_campaign_start_date"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"campaign end date","tag":"div","rules":"required|after:@campaign start date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputDate',{attrs:{"id":"end-date","label":"Campaign End Date","add-calendar":true,"required":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0],"inputclass":"form-control"},model:{value:(_vm.campaign.actual_campaign_end_date),callback:function ($$v) {_vm.$set(_vm.campaign, "actual_campaign_end_date", $$v)},expression:"campaign.actual_campaign_end_date"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"paused_date","tag":"div","rules":"afterdate:@campaign start date|beforedate:@campaign end date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputDate',{attrs:{"id":"pause-date","label":"Campaign Pause Date","add-calendar":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.paused_date),callback:function ($$v) {_vm.$set(_vm.campaign, "paused_date", $$v)},expression:"campaign.paused_date"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{staticClass:"form-group",attrs:{"name":"unpaused_date","tag":"div","rules":"afterpause:@paused_date|beforeend:@campaign end date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputDate',{attrs:{"id":"unpause-date","label":"Campaign Restart Date","add-calendar":true,"state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.unpaused_date),callback:function ($$v) {_vm.$set(_vm.campaign, "unpaused_date", $$v)},expression:"campaign.unpaused_date"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroInputText',{attrs:{"id":"churn-date","value":_vm.getDate(_vm.campaign.churn_date),"label":"Churn Date","readonly":true}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroInputText',{attrs:{"id":"google-account-id","value":_vm.getGoogleAccIds,"label":"Google Account ID","add-more":true,"readonly":true},on:{"addMore":function($event){return _vm.$bvModal.show('google-account-id')}}}),_c('AddGoogleAcc',{attrs:{"campaign":_vm.campaign}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroInputText',{attrs:{"id":"facebook-account-id","value":_vm.getFacebookAccIds,"label":"Facebook Account ID","add-more":true,"readonly":true},on:{"addMore":function($event){return _vm.$bvModal.show('facebook-account-id')}}}),_c('AddFacebookAcc',{attrs:{"campaign":_vm.campaign}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroInputText',{attrs:{"id":"tiktok-account-id","value":_vm.getTikTokAccIds,"label":"TikTok Account ID","add-more":true,"readonly":true},on:{"addMore":function($event){return _vm.$bvModal.show('tiktok-account-id')}}}),_c('AddTikTokAcc',{attrs:{"campaign":_vm.campaign}})],1),(_vm.isEditMode)?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroInputText',{attrs:{"id":"bonus","label":"Bonus/Discount"},model:{value:(_vm.campaign.bonus),callback:function ($$v) {_vm.$set(_vm.campaign, "bonus", $$v)},expression:"campaign.bonus"}})],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"GA property Id","rules":"max:255|validgaproperty"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"ga-property-id","label":"GA Property ID","state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.ga_property_id),callback:function ($$v) {_vm.$set(_vm.campaign, "ga_property_id", $$v)},expression:"campaign.ga_property_id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"GA profile Id","rules":"max:255|validgaprofile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"ga-profile-id","label":"GA Profile ID","state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.ga_profile_id),callback:function ($$v) {_vm.$set(_vm.campaign, "ga_profile_id", $$v)},expression:"campaign.ga_profile_id"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"client website","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"client-website","label":"Client Website","state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.client_website),callback:function ($$v) {_vm.$set(_vm.campaign, "client_website", $$v)},expression:"campaign.client_website"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroVueSelect',{attrs:{"id":"client_at_risk_stage","label":"Client At Risk Stage","options":_vm.clientAtRiskOptions,"clearable":false},model:{value:(_vm.campaign.client_at_risk_stage),callback:function ($$v) {_vm.$set(_vm.campaign, "client_at_risk_stage", $$v)},expression:"campaign.client_at_risk_stage"}})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"reason for high risk","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('HeroInputText',{attrs:{"id":"reason_for_high_risk","label":"Reason For High Risk","state":errors.length > 0 ? false : null,"invalid-feedback":errors[0]},model:{value:(_vm.campaign.reason_for_high_risk),callback:function ($$v) {_vm.$set(_vm.campaign, "reason_for_high_risk", $$v)},expression:"campaign.reason_for_high_risk"}})]}}])})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('HeroCheckbox',{attrs:{"id":"demo-campaign","label":"Demo Campaign"},model:{value:(_vm.campaign.demo_campaign),callback:function ($$v) {_vm.$set(_vm.campaign, "demo_campaign", $$v)},expression:"campaign.demo_campaign"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }