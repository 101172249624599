<template>
  <validation-observer ref="emailLeadListRules" v-slot="{ invalid }" tag="form">
    <b-modal
      id="email-lead-list"
      size="lg"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-card-code title="Enter Email Leads">
        <div class="card-body-data">
          <b-form
            ref="form"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >
            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col md="10">
                <validation-provider
                  #default="{ errors }"
                  name="Email Leads"
                  rules="required|email"
                >
                  <HeroInputText
                    id="`email-leads${index}`"
                    v-model="emails[index]"
                    label="Email Leads"
                    :required="true"
                    :state="errors.length > 0 ? false : null "
                    :invalid-feedback="errors[0]"
                  />
                </validation-provider>
              </b-col>
              <b-col
                lg="2"
                md="2"
                class="d-flex align-items-center"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="danger"
                  class="btn"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                  />
                </b-button>
              </b-col>
              <b-col cols="12" />
            </b-row>
          </b-form>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span>Add Email Leads</span>
        </b-button>
      </b-card-code>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-right"
            :disabled="invalid"
            @click="validationFormEmailLeadList(true)"
          >
            Save
          </b-button>
          <b-button
            variant="danger"
            size="md"
            class="float-right mr-2"
            @click="validationFormEmailLeadList(false)"
          >
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    HeroInputText,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    webSolBriefing: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [{
        id: 1,
        prevHeight: 0,
      }],
      nextTodoId: 2,
      emails: [],
    }
  },
  mounted() {
    this.initTrHeight()
    setTimeout(() => {
      this.emails = []
      if (this.webSolBriefing.email_lead_list && this.webSolBriefing.email_lead_list.length > 0) {
        for (let emailcount = 0; emailcount < this.webSolBriefing.email_lead_list.length; emailcount += 1) {
          this.emails.push(this.webSolBriefing.email_lead_list[emailcount])
          if (emailcount > 0) {
            this.items.push({
              id: this.nextTodoId += this.nextTodoId,
            })
          }
          this.$nextTick(() => {
            if (this.$refs.row) {
              this.trAddHeight(this.$refs.row[0].offsetHeight)
            }
          })
        }
      } else {
        this.emails = ['']
      }
    }, 0)
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })
      this.emails.push('')
      this.$nextTick(() => {
        if (this.$refs.row) {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
        }
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.emails.splice(index, 1)
      if (this.$refs.row) {
        this.trTrimHeight(this.$refs.row[0].offsetHeight)
      }
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.trSetHeight(this.$refs.form.scrollHeight)
        }
      })
    },
    validationFormEmailLeadList(isSave) {
      return new Promise((resolve, reject) => {
        if (isSave) {
          this.$refs.emailLeadListRules.validate().then(success => {
            if (success) {
              this.webSolBriefing.email_lead_list = []
              if (this.emails) {
                const emailList = this.emails.map(item => item.toLowerCase())
                const UniqueEmails = [...new Set(emailList)]
                if (emailList.length !== UniqueEmails.length) {
                  this.$swal('Duplicate enteries in Email Lead List')
                  reject()
                } else {
                  this.emails.forEach(email => {
                    this.webSolBriefing.email_lead_list.push(email)
                  })
                  this.$bvModal.hide('email-lead-list')
                  resolve(true)
                }
              } else {
                this.$bvModal.hide('email-lead-list')
                resolve(true)
              }
            } else {
              reject()
            }
          })
        } else {
          this.$bvModal.hide('email-lead-list')
          resolve(true)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.btn {
  margin-top: 8px;
}
</style>
