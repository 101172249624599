<template>
  <b-form-group
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }} <span v-if="required" class="text-danger">*</span>
      </span>
    </template>
    <v-select
      :id="id"
      v-model="formValue"
      :placeholder="placeholder"
      :options="options"
      :multiple="true"
      :close-on-select="false"
      :disabled="disabled"
      :state="state"
      label="text"
      :reduce="option => option.value"
      @option:deselecting="$emit('deselecting')"
      @option:deselected="$emit('deselected')"
      @option:selected="$emit('selected')"
    />
    <b-form-valid-feedback :state="state">
      {{ validFeedback }}
    </b-form-valid-feedback>
    <b-form-invalid-feedback :state="state">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BFormValidFeedback,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,
    BFormValidFeedback,
    BFormInvalidFeedback,
    vSelect,
  },
  props: {
    // Got value from v-model
    value: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: false,
    },
    label: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: null,
    },
    column: {
      type: String,
      required: false,
      default: null,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    validFeedback: {
      type: String,
      required: false,
      default: null,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        if (value !== null) {
          this.$emit('input', value)
        } else {
          this.$emit('input', '')
        }
      },
    },
  },
}
</script>
